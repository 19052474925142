// Variables
$nav-link-padding-y: 0;

$navbar-white-color: rgba(#fff, 1);
$navbar-white-hover-color: rgba($white, 0.75);
$navbar-white-active-color: $white;
$navbar-white-disabled-color: rgba($white, 0.25);
$navbar-white-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-white-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-white-toggler-border-color: rgba($white, 0.1);

$navbar-white-brand-color: $navbar-white-active-color;
$navbar-white-brand-hover-color: $navbar-white-active-color;

// =================================================================
.navbar-white {
	.navbar-brand {
		color: $navbar-white-brand-color;

		&:hover,
		&:focus {
			color: $navbar-white-brand-hover-color;
		}
	}

	.navbar-nav {
		.nav-link {
			color: $navbar-white-color;
			// border-bottom: solid 3px transparent;
			margin: 0 0.5rem;

			&:hover,
			&:focus {
				color: $navbar-white-hover-color;
				// border-bottom: solid 3px $navbar-white-hover-color;
			}

			&.disabled {
				color: $navbar-white-disabled-color;
			}
		}

		.show > .nav-link,
		.nav-link.active {
			color: $navbar-white-active-color;
		}
	}

	.navbar-toggler {
		color: $navbar-white-color;
		border-color: $navbar-white-toggler-border-color;
	}

	.navbar-toggler-icon {
		background-image: escape-svg($navbar-white-toggler-icon-bg);
	}

	.navbar-text {
		color: $navbar-white-color;
		a,
		a:hover,
		a:focus {
			color: $navbar-white-active-color;
		}
	}
}

.navbar-border-icon {
	width: 3.5rem;
	height: 3.5rem;
	margin: 0 0.75rem;

	display: flex;
	justify-content: center;
	align-items: center;

	border: 2px solid #d33034;
	border-radius: 50%;
}

// Partes obrigatórias
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
