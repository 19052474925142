.bg-image {
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;

	.mask {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		color: #fff;
	}
}

.bg-cover {
	background-image: url('../assets/img/background/video-cta.jpg');
	background-repeat: no-repeat;
	background-size: cover;

	width: 100%;
	height: 18rem;
	object-fit: cover;
	object-position: center;
}
